import {
  BooleanInput,
  DateTimeInput,
  Edit,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";
import { Grid, Typography } from "@mui/material";
import { RichTextInput } from "ra-input-rich-text";
import { CEPAutofillInput } from "../../components/CEPInput";
import { ApiImageField } from "./List";

const FormContent = () => {
  return (
    <Grid container alignSelf="stretch" spacing={2} rowSpacing={-1}>
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom fontWeight="bold" my={2}>
          Editar Evento
        </Typography>

        <Typography variant="h6" gutterBottom fontWeight="bold" mt={4} mb={2}>
          Informações do Evento
        </Typography>

        <ApiImageField source="images" />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          source="name"
          label="Nome do Evento"
          validate={[required()]}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          source="summary"
          label="Descrição Curta"
          validate={[required()]}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <RichTextInput
          source="description"
          label="Descrição do Evento"
          fullWidth
          isRequired
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DateTimeInput
          source="initialDate"
          label="Data e Hora Inicial"
          validate={[required()]}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DateTimeInput
          source="endDate"
          label="Data e Hora Final"
          validate={[required()]}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom fontWeight="bold" mt={4} mb={2}>
          Informações do Local
        </Typography>
      </Grid>
      <Grid item xl={4} lg={4} sm={12} xs={12}>
        <CEPAutofillInput
          addressFields={{
            logradouro: "address.address",
            uf: "address.uf",
            localidade: "address.city",
            bairro: "address.neighborhood",
            complemento: "address.complement",
          }}
          source="address.cep"
          label="CEP"
        />
      </Grid>
      <Grid item xl={8} lg={8} sm={12} xs={12}>
        <TextInput
          source={"address.address"}
          validate={[required()]}
          label={"Endereço"}
          variant="filled"
          fullWidth
        />
      </Grid>

      <Grid item xl={4} lg={4} sm={12} xs={12}>
        <TextInput
          source={"address.number"}
          label={"Número"}
          fullWidth
          variant="filled"
        />
      </Grid>

      <Grid item xl={8} lg={8} sm={12} xs={12}>
        <TextInput
          source={"address.complement"}
          label={"Complemento"}
          fullWidth
          variant="filled"
        />
      </Grid>

      <Grid item xl={4} lg={4} sm={12} xs={12}>
        <TextInput
          source={"address.neighborhood"}
          label={"Bairro"}
          fullWidth
          variant="filled"
        />
      </Grid>

      <Grid item xl={4} lg={4} sm={12} xs={12}>
        <TextInput
          source="address.city"
          label={"Cidade"}
          variant="filled"
          fullWidth
        />
      </Grid>

      <Grid item xl={4} lg={4} sm={12} xs={12}>
        <TextInput
          source="address.uf"
          label={"UF"}
          variant="filled"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom fontWeight="bold" mt={4} mb={2}>
          Opções de Inscrição
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <BooleanInput
          source="canEnroll"
          label="Aceitando Inscrições"
          defaultValue={true}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <BooleanInput
          source="visible"
          label="Visível"
          defaultValue={true}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export const EventsEdit = () => {
  return (
    <Edit resource="events">
      <SimpleForm defaultValues={{ isEventbriteEvent: false }}>
        <FormContent />
      </SimpleForm>
    </Edit>
  );
};
