import React, { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import MaskTextInput from "./MaskTextInput";

const isCepValid = (unsanitizedCep = "") => {
  const cep = unsanitizedCep?.replace(/\D/gi, "");
  if (cep?.length !== 8) {
    return false;
  }
  return true;
};

const buildCepRequestUrl = (unsanitizedCep: string) => {
  if (!isCepValid(unsanitizedCep)) return null;
  const cep = unsanitizedCep.replace(/\D/gi, "");
  return `https://viacep.com.br/ws/${cep}/json/`;
};

export const CEPAutofillInput: React.FC<{
  addressFields: {
    bairro: string;
    complemento: string;
    localidade: string;
    logradouro: string;
    uf: string;
  };
  source: string;
  label: string;
}> = ({ addressFields, source, label, ...props }) => {
  const [loading, setLoading] = useState(false);
  const cep = useWatch({ name: source });
  const { setValue } = useFormContext();

  useEffect(() => {
    if (!isCepValid(cep)) return;
    (async () => {
      setLoading(true);
      const response = await fetch(buildCepRequestUrl(cep)!);
      const address = await response.json();
      if (address.erro) {
        setLoading(false);
        return;
      }

      const fieldsEntries = Object.entries(addressFields);
      for (const [addressField, key] of fieldsEntries) {
        setValue(key, address[addressField]);
      }

      setLoading(false);
    })();
  }, [cep]);

  return (
    <MaskTextInput
      mask="99.999-999"
      source={source}
      label={label}
      disabled={loading}
      {...props}
    />
  );
};
