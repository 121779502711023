import React, { useState } from "react";
import {
  BooleanInput,
  Create,
  DateTimeInput,
  FileInput,
  HttpError,
  ImageField,
  SimpleForm,
  TextInput,
  required,
  useNotify,
} from "react-admin";
import {
  Grid,
  Typography,
  Modal,
  Button as MUIButton,
  Box,
  CircularProgress,
} from "@mui/material";
import { RichTextInput } from "ra-input-rich-text";
import { useWatch, useFormContext } from "react-hook-form";
import { getEventBriteEvent } from "../../api";
import { CEPAutofillInput } from "../../components/CEPInput";

const replaceEmpty = (str: string | null | undefined, defaultValue: string) =>
  str?.length && str !== "None" ? str : defaultValue;

const IntegrateWithEventbrite = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setValue, watch } = useFormContext();
  const eventbriteToken = watch("eventbriteToken");
  const eventbriteEventId = watch("eventbriteEventId");
  const isEventbriteEvent = watch("isEventbriteEvent");
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    if (!loading) {
      handleExit();
    }
  };
  const notify = useNotify();

  const loadDataFromEventBrite = async () => {
    setLoading(true);
    try {
      const eventbriteEvent = await getEventBriteEvent(
        eventbriteToken,
        eventbriteEventId,
      );

      if (!eventbriteEvent) {
        throw new Error(
          "Não foi possível carregar o evento da EventBrite. Verifique as informações e tente novamente.",
        );
      }

      setValue("name", eventbriteEvent.name.text);
      setValue("summary", eventbriteEvent.summary);
      setValue("description", eventbriteEvent.description.html);
      setValue("initialDate", eventbriteEvent.start.utc);
      setValue("endDate", eventbriteEvent.end.utc);
      setValue("isEventbriteEvent", true);
      setValue("address", {
        cep: eventbriteEvent.venue.address.postal_code,
        city: eventbriteEvent.venue.address.city,
        neighborhood: replaceEmpty(
          eventbriteEvent.venue.address.neighborhood,
          "-",
        ),
        uf: eventbriteEvent.venue.address.region,
        address: replaceEmpty(eventbriteEvent.venue.address.address_1, "-"),
        number: replaceEmpty(eventbriteEvent.venue.address.address_2, "-"),
      });

      setLoading(false);
      setOpen(false);
      notify("Evento carregado com sucesso!", { type: "success" });
    } catch (error: unknown) {
      notify((error as HttpError).message ?? error, { type: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleExit = () => {
    setValue("eventbriteToken", null);
    setValue("eventbriteEventId", null);
    setValue("isEventbriteEvent", false);
    setOpen(false);
  };

  const handleRemoveIntegration = () => {
    setValue("eventbriteToken", null);
    setValue("eventbriteEventId", null);
    setValue("isEventbriteEvent", false);
  };

  return (
    <Grid item xs={12}>
      {isEventbriteEvent ? (
        <MUIButton
          onClick={handleRemoveIntegration}
          variant="contained"
          color="error"
        >
          Remover Integração com Event Brite
        </MUIButton>
      ) : (
        <MUIButton onClick={handleOpen} variant="contained">
          Integrar com EventBrite
        </MUIButton>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "1px solid #ececec",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Carregar Evento da EventBrite
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 1, mb: 2 }}>
            Para carregar o evento da EventBrite, acesse https://eventbrite.com,
            copie o token de autenticação e o ID do evento e cole nos campos
            abaixo.
          </Typography>

          <TextInput
            source="eventbriteToken"
            label="Token de Autenticação"
            validate={[required()]}
            disabled={loading}
            fullWidth
          />
          <TextInput
            source="eventbriteEventId"
            label="ID do Evento"
            validate={[required()]}
            disabled={loading}
            fullWidth
          />

          <Box sx={{ ml: "auto" }}>
            <MUIButton
              onClick={loadDataFromEventBrite}
              sx={{ mt: 2 }}
              variant="contained"
            >
              Carregar informações
              {loading && (
                <CircularProgress size={20} color="inherit" sx={{ ml: 1 }} />
              )}
            </MUIButton>
            <MUIButton
              onClick={handleExit}
              sx={{ mt: 2, ml: 2 }}
              variant="contained"
              color="error"
            >
              Cancelar
            </MUIButton>
          </Box>
        </Box>
      </Modal>
    </Grid>
  );
};

const FormContent = () => {
  const isEventbriteEvent = useWatch({ name: "isEventbriteEvent" });
  return (
    <Grid container alignSelf="stretch" spacing={2} rowSpacing={-1}>
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom fontWeight="bold" my={2}>
          Criar Novo Evento
        </Typography>

        <IntegrateWithEventbrite />

        <Typography variant="h6" gutterBottom fontWeight="bold" mt={4} mb={2}>
          Informações do Evento
        </Typography>
        <FileInput
          source="images"
          label="Imagens"
          accept="image/*"
          multiple
          validate={[required()]}
          fullWidth
        >
          {/* <FileField source="src" title="title" /> */}
          <ImageField source="src" title="title" />
        </FileInput>
      </Grid>
      <Grid item xs={12}>
        <TextInput
          source="name"
          label="Nome do Evento"
          validate={[required()]}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          source="summary"
          label="Descrição Curta"
          validate={[required()]}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <RichTextInput
          source="description"
          label="Descrição do Evento"
          fullWidth
          isRequired
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DateTimeInput
          source="initialDate"
          label="Data e Hora Inicial"
          validate={[required()]}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DateTimeInput
          source="endDate"
          label="Data e Hora Final"
          validate={[required()]}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom fontWeight="bold" mt={4} mb={2}>
          Informações do Local
        </Typography>
      </Grid>
      <Grid item xl={4} lg={4} sm={12} xs={12}>
        <CEPAutofillInput
          addressFields={{
            logradouro: "address.address",
            uf: "address.uf",
            localidade: "address.city",
            bairro: "address.neighborhood",
            complemento: "address.complement",
          }}
          source="address.cep"
          label="CEP"
        />
      </Grid>
      <Grid item xl={8} lg={8} sm={12} xs={12}>
        <TextInput
          source={"address.address"}
          validate={[required()]}
          label={"Endereço"}
          variant="filled"
          fullWidth
        />
      </Grid>

      <Grid item xl={4} lg={4} sm={12} xs={12}>
        <TextInput
          source={"address.number"}
          label={"Número"}
          fullWidth
          variant="filled"
        />
      </Grid>

      <Grid item xl={8} lg={8} sm={12} xs={12}>
        <TextInput
          source={"address.complement"}
          label={"Complemento"}
          fullWidth
          variant="filled"
        />
      </Grid>

      <Grid item xl={4} lg={4} sm={12} xs={12}>
        <TextInput
          source={"address.neighborhood"}
          label={"Bairro"}
          fullWidth
          variant="filled"
        />
      </Grid>

      <Grid item xl={4} lg={4} sm={12} xs={12}>
        <TextInput
          source="address.city"
          label={"Cidade"}
          variant="filled"
          fullWidth
        />
      </Grid>

      <Grid item xl={4} lg={4} sm={12} xs={12}>
        <TextInput
          source="address.uf"
          label={"UF"}
          variant="filled"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom fontWeight="bold" mt={4} mb={2}>
          Opções de Inscrição
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <BooleanInput
          source="canEnroll"
          label="Aceitando Inscrições"
          defaultValue={true}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <BooleanInput
          source="visible"
          label="Visível"
          defaultValue={true}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export const EventsCreate = () => {
  return (
    <Create resource="events">
      <SimpleForm defaultValues={{ isEventbriteEvent: false }}>
        <FormContent />
      </SimpleForm>
    </Create>
  );
};
