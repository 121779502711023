import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";

const ListFilters = [
  <ReferenceInput source="eventId" reference="events" label="Evento">
    <AutocompleteInput label="Nome do Evento" optionText="name" />
  </ReferenceInput>,
];

export const AttendeesList = () => {
  return (
    <List resource="attendee" pagination={false} filters={ListFilters}>
      <Datagrid
        rowClick={false}
        sort={undefined}
        isRowSelectable={() => false}
        isRowExpandable={() => false}
      >
        <TextField source="firstName" label="Nome" sortable={false} />
        <TextField source="lastName" label="Sobrenome" sortable={false} />
        <TextField source="email" label="Email" sortable={false} />
        <TextField source="status" label="Status" sortable={false} />
        <ReferenceField source="eventId" reference="events" label="Evento">
          <TextField source="name" />
        </ReferenceField>
        <DateField
          source="createdAt"
          label="Cadastrado em"
          showTime
          sortable={false}
        />
      </Datagrid>
    </List>
  );
};
